<template>
  <!-- 商品分类 -->
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>产品分类</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px"
        ><i style="font-size: 18px" class="el-icon-refresh-right"></i
      ></el-button>
    </div>
    <div class="addBtn">
      <el-button type="danger" @click="addSame"
        ><i class="el-icon-plus"></i>新增同级</el-button
      >
      <el-button type="primary" @click="addDown"
        ><i class="el-icon-plus"></i>新增下级分类</el-button
      >
    </div>
    <div style="display: flex; align-items: center">
      <div class="marinLeft">
        <ul>
          <li
            v-for="label in leftLabels"
            :key="label.id"
            :class="isLabel == label.id ? 'label' : ''"
            @click="row1Click(label.id)"
          >
            <div class="row" @click="labelClick(label)">
              <img
                style="width: 16px; height: 16px"
                :src="
                  label.children.length == 0
                    ? require('@/assets/mianAssets/空白页.png')
                    : require('@/assets/mianAssets/文件夹.png')
                "
                alt=""
              />
              <a style="margin-left: 2px">{{ label.classify_name }}</a>
            </div>
            <div v-if="row1 == label.id">
              <div
                v-for="label1 in label.children"
                :key="label1.id"
                style="margin: 4px 10px"
                @click="row2Click(label1.id, $event)"
              >
                <div class="row" @click="labelClick(label1)">
                  <img
                    style="width: 16px; height: 16px"
                    :src="
                      label1.children.length == 0
                        ? require('@/assets/mianAssets/空白页.png')
                        : require('@/assets/mianAssets/文件夹.png')
                    "
                    alt=""
                  />
                  <a style="margin-left: 2px">{{ label1.classify_name }}</a>
                </div>
                <div v-if="row2 == label1.id">
                  <div
                    v-for="label2 in label1.children"
                    :key="label2.id"
                    style="margin: 4px 10px"
                    class="row"
                    @click="labelClick(label2, $event)"
                  >
                    <img
                      style="width: 16px; height: 16px"
                      :src="require('@/assets/mianAssets/空白页.png')"
                      alt=""
                    />
                    <a style="margin-left: 2px">{{ label2.classify_name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mianRight">
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            style="padding: 4px"
            label-width="100px"
            class="demo-ruleForm"
            enctype="multipart/form-data"
          >
            <el-form-item label="名称：" prop="classify_name">
              <el-input v-model="ruleForm.classify_name"></el-input>
            </el-form-item>
            <el-form-item label="上级分类：" prop="superiorClassify">
              <span>{{ ruleForm.superiorClassify }}</span>
            </el-form-item>
            <el-form-item label="缩略图：" prop="thumbnail">
              <div>
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="uploadImg"
                >
                  <img
                    v-if="ruleForm.thumbnail"
                    :src="'http://192.168.3.3' + ruleForm.thumbnail"
                    class="avatar"
                  />
                  <el-button size="small" type="primary" class="uploadBtu"
                    >选择图片</el-button
                  >
                </el-upload>
              </div>
              <el-checkbox-group v-model="ruleForm.isThumbnail">
                <el-checkbox
                  label="是否显示"
                  name="isThumbnail"
                  :true-label="1"
                  :false-label="0"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input v-model="ruleForm.sort"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">{{
                amend ? "修改" : "提交"
              }}</el-button>
              <el-button type="primary" @click="deleteClick" v-if="amend"
                >删除</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 文件夹列表
      isLabel: -1,
      leftLabels: [],
      labelName: "",

      // 是否切换表单

      // 表单数据
      imageUrl: "",

      // 表单数据 -- 同级分类表单
      ruleForm: {
        pid: "",
        classify_name: "",
        thumbnail: "",
        superiorClassify: "顶级分类",
        isThumbnail: 0,
        sort: "",
        cate_level: "",
        commercial_id: "",
      },
      rules: {
        classify_name: [{ required: true, message: " ", trigger: "blur" }],
        superiorClassify: [{ required: true, trigger: "change" }],
      },

      // 列表选择id
      id: "",

      // 表单数据 -- 下级分类表单
      ruleFormY: {
        pid: "",
        thumbnail: "",
        superiorClassify: "顶级分类",
        isThumbnail: 0,
        classify_name: "",
        sort: "",
        commercial_id: "",
        cate_level: "",
      },
      commercial_id: "",
      cate_level: 1,
      isSame: true,
      superiorClassify: "",
      pid: "",
      row1: "",
      row2: "",
      amend: false,
    };
  },
  mounted() {
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.ruleFormY.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.inquireList();
  },
  computed: {},
  methods: {
    // 提交表单
    submitForm(formName) {
      if (formName == "ruleForm") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.isSame) {
              this.ruleForm.cate_level = this.cate_level;
              this.ruleForm.pid = this.pid;
            } else {
              this.ruleForm.cate_level = this.cate_level + 1;
              this.ruleForm.pid = this.id;
            }
            this.$http
              .post("/Goods_Category/add", this.ruleForm)
              .then((res) => {
                this.$message({
                  type: res.data.status,
                  message: res.data.msg,
                });
                if (res.data.status == "success") {
                  this.inquireList();
                  this.amend = true;
                }
              });
          } else {
            this.$message.error("必填选项未填写,请重新填写提交!");
            return false;
          }
        });
      }
    },

    async uploadImg(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.imageUrl = image.data.path;
        this.ruleForm.thumbnail = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    row1Click(id) {
      if (this.row1 == id) {
        this.row1 = "";
        return;
      }
      this.row1 = id;
    },
    row2Click(id, e) {
      e.stopPropagation();
      if (this.row2 == id) {
        this.row2 = "";
        return;
      }
      this.row2 = id;
    },

    // 列表数据
    inquireList() {
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: this.ruleFormY.commercial_id,
        })
        .then((res) => {
          this.leftLabels = res.data.data;
        });
    },
    // 列表详情
    labelClick(data, e) {
      if (e) {
        e.stopPropagation();
      }
      this.id = data.id;
      this.amend = true;
      this.pid = data.pid;
      this.cate_level = data.cate_level;
      this.$http
        .post(
          "/Goods_Category/detail",
          this.$qs.stringify({
            id: this.id,
          })
        )
        .then((res) => {
          this.imageUrl = res.data.thumbnail;
          this.ruleForm = res.data;
          this.labelName = res.data.classify_name;
          this.superiorClassify = res.data.superiorClassify;
        });
    },
    // 添加同级
    addSame() {
      this.isSame = true;
      this.ruleForm = this.ruleFormY;
      this.ruleForm.superiorClassify = this.superiorClassify;
      this.ruleForm.thumbnail = "";
      this.ruleForm.classify_name = "";
      this.ruleForm.isThumbnail = 0;
      this.ruleForm.sort = "";
      this.amend = false;
    },
    // 添加下级
    addDown() {
      this.isSame = false;
      this.ruleForm = this.ruleFormY;
      this.ruleForm.superiorClassify = this.labelName;
      this.ruleForm.thumbnail = "";
      this.ruleForm.classify_name = "";
      this.ruleForm.isThumbnail = 0;
      this.ruleForm.sort = "";
      this.amend = false;
    },
    // 删除单条数据
    deleteClick() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Goods_Category/del", { id: this.id })
            .then((res) => {
              this.$message({
                type: res.data.status,
                message: res.data.msg,
              });
              if (res.data.status == "success") {
                this.inquireList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

/deep/ .uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

/deep/ .el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.active {
  display: block;
}

.none {
  display: none;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addBtn {
  height: 43px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 5px;
  background-color: #f5fafe;
  border-bottom: 1px solid #eeeeee;

  .el-button {
    height: 31px;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    padding: 4px 12px;
  }
}

.marinLeft {
  width: 225px;
  align-self: flex-start;
  padding: 5px;
  margin: 5px 0 0 30px;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      font-size: 12px;

      padding: 2px 0;
      cursor: pointer;
      .row {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.label {
  background-color: #ffeabf;
}

.mianRight {
  padding: 28px;
  margin-top: 20px;
  width: 65%;
}
</style>
